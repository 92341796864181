<template>
  <div class="row">
    <div class="row col-12 mb-4 justify-content-center align-items-center">
      <div class="col-3 text-center">
        <div class="bold-24">
          <EditOnClick
            :model-value="total"
            type="price"
            :editable="false"
            :padding="false"
          />
        </div>
        <div class="text-black mx-2 regular-14">
          {{ $t("finance.totalBalance") }}
        </div>
      </div>
      <div class="col-9">
        <div
          class="badge badge-outline w-100 p-0 mb-2"
          :style="{
            overflow: 'hidden',
            height: '2em',
          }"
        >
          <span
            v-for="(account, i) in accounts.filter(
              (e) => e.converted_balance.amount !== 0
            )"
            :key="account.id"
            class="d-inline-block"
            :style="{
              background: getColor(i),
              height: '2em',
              width:
                (account.converted_balance.amount / totalPlus.amount) * 100 +
                '%',
            }"
          ></span>
        </div>
        <div class="row">
          <div
            v-for="(account, i) in accounts.filter(
              (e) => e.converted_balance.amount !== 0
            )"
            :key="account.id"
            class="col-4 row"
          >
            <div class="col-2">
              <span
                class="color-circle"
                :style="{ background: getColor(i) }"
              ></span>
            </div>
            <div class="col-6 overflow-hidden">
              <span>{{ account.name }}</span>
            </div>
            <div class="col-4">
              <EditOnClick
                :model-value="account.balance"
                type="price"
                :editable="false"
                :padding="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="col-12 mt-3 row error-notify"
        v-for="account in accounts.filter((e) => e.has_error)"
        :key="account.id"
      >
        <div class="failed badge w-100">
          <span class="bold-19">{{
            $t(`finance.connectionErrorIn`, { name: account.name })
          }}</span>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="white-box">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 row justify-content-center">
          <h2 class="bold-19">{{ $t("finance.expensesByCategory") }}</h2>
          <div class="col-9 mb-4">
            <PieChart :chartData="cartByCategories" />
          </div>
          <div>
            <div
              class="row"
              v-for="(payment, i) in byCategories"
              :key="payment.id"
            >
              <div class="col-1">
                <span
                  class="color-circle"
                  :style="{ background: getColor(i) }"
                ></span>
              </div>
              <div class="col-7">
                {{ payment.name }}
              </div>
              <div class="col-4">
                <EditOnClick
                  :model-value="{
                    amount: payment.total,
                    currency: currency,
                  }"
                  type="price"
                  :editable="false"
                  :padding="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="white-box">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 row justify-content-center">
          <h2 class="bold-19">{{ $t("finance.expensesByPartner") }}</h2>
          <div class="col-9 mb-4">
            <PieChart :chartData="cartByPartners" />
          </div>
          <div>
            <div
              class="row"
              v-for="(payment, i) in byPartners"
              :key="payment.id"
            >
              <div class="col-1">
                <img
                  :src="payment.logo"
                  v-if="payment.logo"
                  class="logo-image"
                />
                <span
                  v-else
                  class="color-circle"
                  :style="{ background: getColor(i) }"
                ></span>
              </div>
              <div class="col-7">
                {{ payment.name }}
              </div>
              <div class="col-4">
                <EditOnClick
                  :model-value="{
                    amount: payment.total,
                    currency: currency,
                  }"
                  type="price"
                  :editable="false"
                  :padding="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="white-box">
        <div class="white-box-top"></div>
        <div class="px-3 py-2 row justify-content-center">
          <h2 class="bold-19">{{ $t("finance.cardPaymentsByCategory") }}</h2>
          <div class="col-9 mb-4">
            <PieChart :chartData="cartByMcc" />
          </div>
          <div>
            <div class="row" v-for="(payment, i) in byMcc" :key="payment.id">
              <div class="col-1">
                <span
                  class="color-circle"
                  :style="{ background: getColor(i) }"
                ></span>
              </div>
              <div class="col-7">
                {{ payment.name }}
              </div>
              <div class="col-4">
                <EditOnClick
                  :model-value="{
                    amount: payment.total,
                    currency: currency,
                  }"
                  type="price"
                  :editable="false"
                  :padding="false"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import http from "../../modules/http";
import date from "@/modules/date";
import { useStore } from "vuex";
import EditOnClick from "../../components/inputs/EditOnClick.vue";
import PaymentTypes from "../../modules/PaymentTypes";
import { PieChart } from "vue-chart-3";

export default {
  name: "Finance",
  components: { EditOnClick, PieChart },

  data() {
    return {
      colors: [
        "#4CC9F0",
        "#F9DB6D",
        "#464D77",
        "#F71735",
        "#04A777",
        "#FDFFFC",
        "#3A0CA3",
        "#F79824",
        "#4F5165",
        "#6FAE90",
        "#7D2E68",
        "#502419",
        "#645DD7",
        "#C7CB85",
        "#C7FFED",
        "#9DB5B2",
        "#55505C",
      ],
      store: useStore(),
      firstLoad: true,
      loadID: null,
      currency: "EUR",
      accounts: [],
      companies: [],
      cards: [],
      mcc: [],
      categories: [],
      byPartners: [],
      byCategories: [],
      byMcc: [],
      cartByMcc: {
        labels: [],
        datasets: [
          {
            label: "Expenses",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      cartByCategories: {
        labels: [],
        datasets: [
          {
            label: "Expenses",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      cartByPartners: {
        labels: [],
        datasets: [
          {
            label: "Expenses",
            data: [],
            lineTension: 0.45,
            backgroundColor: [],
            fill: true,
          },
        ],
      },
      paymentTypes: PaymentTypes,
    };
  },
  computed: {
    user() {
      return this.$route.params.user;
    },
    warehouse() {
      return this.store.state.topBar.warehouse;
    },
    warehouses() {
      return this.store.state.warehouses;
    },
    date() {
      return this.store.state.topBar.date;
    },
    filters() {
      return this.store.state.filters?.finance;
    },
    q() {
      return this.store.state.topBar.q;
    },
    currencies() {
      return this.store.state.currencies;
    },
    countries() {
      return this.store.state.countries;
    },
    total() {
      if (this.accounts.length < 1) {
        return { amount: 0, currency: "EUR" };
      }
      let sum = 0;
      for (const account of this.accounts) {
        sum += account.converted_balance.amount;
      }
      return {
        amount: sum,
        currency: this.accounts[0].converted_balance.currency,
      };
    },
    totalPlus() {
      if (this.accounts.length < 1) {
        return { amount: 0, currency: "EUR" };
      }
      let sum = 0;
      for (const account of this.accounts) {
        if (account.converted_balance.amount > 0) {
          sum += account.converted_balance.amount;
        }
      }
      return {
        amount: sum,
        currency: this.accounts[0].converted_balance.currency,
      };
    },
  },
  mounted() {
    this.$store.commit("setFilterPage", "finance");
    this.loadData();
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        if (this.filtersTimeOut !== null) {
          clearTimeout(this.filtersTimeOut);
        }
        this.filtersTimeOut = setTimeout(() => {
          this.filtersTimeOut = null;
          this.items = undefined;
          this.page = 1;
          this.loadData();
        }, 300);
      },
    },
    q() {
      if (this.filtersTimeOut !== null) {
        clearTimeout(this.filtersTimeOut);
      }
      this.filtersTimeOut = setTimeout(() => {
        this.filtersTimeOut = null;
        this.page = 1;
        this.items = undefined;
        this.loadData();
      }, 450);
    },
    date: {
      deep: true,
      handler() {
        this.loadData();
      },
    },
  },
  methods: {
    getColor(i) {
      let e = i - this.colors.length * Math.floor(i / this.colors.length);
      return this.colors[e];
    },
    formatDate(val) {
      return date.format(val, true, true);
    },
    getUrl(exportUrl = false) {
      let url =
        `/payments/statistics${exportUrl ? "/export/{type}" : ""}?page=` +
        (exportUrl ? 1 : this.page) +
        (this.warehouse ? "&warehouse=" + this.warehouse : "") +
        (this.q ? "&q=" + this.q : "") +
        (this.date[0]
          ? "&from=" + this.date[0].toISOString().split("T")[0]
          : "") +
        (this.date[1] ? "&to=" + this.date[1].toISOString().split("T")[0] : "");

      if (this.user !== null && this.user !== undefined) {
        url += `&customer=${this.user}`;
      }

      if (Object.keys(this.filters).length > 0) {
        for (const filter in this.filters) {
          if (Array.isArray(this.filters[filter].value)) {
            for (const value of this.filters[filter].value) {
              url += `&${filter}[]=${value}`;
            }
          } else {
            url += `&${filter}=${this.filters[filter].value}`;
          }
        }
      }
      return url;
    },
    loadData() {
      const loadID = (this.loadID = Math.random());

      if (this.firstLoad) {
        this.items = undefined;
        http.fetch("/companies").then((data) => {
          this.companies = data;
        });
        http.fetch("/payments/accounts").then((data) => {
          this.accounts = data;
        });
        http.fetch("/payments/cards").then((data) => {
          this.mcc = data.mcc;
          this.cards = data.cards.map((e) => {
            e.full = e.last_digits + " " + e.name;
            return e;
          });
        });
      }
      http.fetch(this.getUrl()).then((data) => {
        if (this.loadID !== loadID) {
          return;
        }
        this.byPartners = data.by_partners;
        this.byCategories = data.by_categories;
        this.byMcc = data.by_mcc;
        this.setChart(this.byPartners, this.cartByPartners);
        this.setChart(this.byMcc, this.cartByMcc);
        this.setChart(this.byCategories, this.cartByCategories);

        if (this.firstLoad) {
          this.firstLoad = false;
        }
      });
    },
    setChart(data, chartset) {
      let chart = [];
      let colors = [];

      for (const i in data) {
        chart.push(data[i].total);
        colors.push(this.getColor(i));
      }

      chartset.datasets[0].data = chart;
      chartset.datasets[0].backgroundColor = colors;
    },
  },
};
</script>
